<template>
 <el-dialog :visible="visible" :showClose="false" :modal="false" lock-scroll style="position: absolute">
  <div class="zoomShow" :style="parameter.zoomStyle">
<!--    <div v-if="parameter.title" style="position: absolute;">{{parameter.title}}</div>-->
    <img ref="zoomOut" :src="zoomOutImg" class="zoomOut" @click="onClose"/>
    <img :src="parameter.img" :style="parameter.style" ref="img1"/>
    <div v-if="parameter.htmls" v-for="(item) in parameter.htmls" :class="item.class" :style="item.style" @click="otherEvent(item.class)">{{item.text}}</div>
  </div>
 </el-dialog>
</template>

<script>
export default {
 props: {
  visible: {
   type: Boolean,
   default: true,
  },
  parameter:{},
  optionType: {
   type: String,
  },

  close: {
   type: Function,
  },
 },
  data() {
    return {
      zoomOutImg: require('@/assets/img/zoomOut.png'),
    }
  },
 created() {
   // console.log('zoomImg',this.parameter)
 },
  mounted(){
  },
 methods: {
  onClose() {
   this.close && this.close();
   this.$emit('customPlay2', 'magnifying-xiao.mp3');
  },
  buttonSelect(value,e){
   this.$emit('customPlay2', 'keyboard.mp3');
  },
  otherEvent(value){
    if(this.parameter[value]){
      if(this.parameter[value]['up']&&(this.parameter[value]['submit']['equal']===1&&this.parameter.branchKey===this.parameter[value]['submit']['branch']||this.parameter[value]['submit']['equal']===3)){
        let arr=this.parameter[value]['up'];
        let new_=this.arrcover(arr,this.parameter)
        this.$set(this.parameter,new_)
      }
    }
    let submit=[];let update_=[];
    if(this.parameter[value]&&this.parameter[value]['submit']){
      submit=this.parameter[value]['submit'];
    }
    if(this.parameter[value]&&this.parameter[value]['update']){
      console.log('subUpdate1',this.parameter[value]['update'])
      update_= this.parameter[value]['update'];
    }

    this.$emit('customOther', value,submit,update_);
   },
   arrcover(arr,new_){
     Object.keys(arr).forEach((k)=>{
       if(typeof arr[k] === 'object'){
         if(!new_[k]){
           new_[k]={};
         }
         this.arrcover(arr[k],new_[k])
       }
       else{
         new_[k]=arr[k];
       }
     })
     return new_;
   },
 },
};
</script>

<style lang="less" scoped>
// 屏幕
.el-dialog__wrapper {
 position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  margin: 0;
}
/deep/.el-dialog {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -ms-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -ms-flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-height: calc(100% - 1.875rem);
  max-width: calc(100% - 1.875rem);
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  width: auto;
}
/deep/.el-dialog .el-dialog__body {
  max-height: 100%;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex: 1 1 auto;
  overflow-y: hidden;
  overflow-x: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
/deep/ .zoomOut{
  width: 2.8125rem;
  height: 2.8125rem;
  cursor: pointer;
  position: absolute;
  right: 1.25rem;
  top: 1.25rem;
  z-index: 999;
}
/deep/ .zoomShow{
  position: relative;
  border: 8px solid #fff;
  border-radius: 16px;
  background-color: #fff;
  img{
    max-width: 100rem;
  }
}
</style>
