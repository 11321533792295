<template>
  <div class="toolboxs">
    <div class="toolbox" >
      <div class="title">工具箱</div>
      <div class="tools">
        <div class="tool" v-for="(item, index) in toolboxImg"   @click="selectTool(item.answer,index,$event)">
          <img  :src="item.src"><span>{{ item.name }}</span>
        </div>
      </div>
      <div class="vertical-scroll"><div class="rail" style="height: 50%;"></div></div>
      <div class="confirm" @click="subToolBox"><button type="button" class="el-button button el-button--primary el-button--medium"><!----><!----><span>确定</span></button>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  data() {
    return {
      toolboxImg: [
        {
          answer: 2,
          src: require("@/assets/img/toolbox/temperature.png"),
          name: "感温探测器功能试验器",
        },
        {
          answer: 1,
          src: require("@/assets/img/toolbox/smoke-1.png"),
          name: "感烟探测器功能试验器",
        },
        {
          answer: 6,
          src: require("@/assets/img/toolbox/wrench.png"),
          name: "六角扳手",
        },
        {
          answer: 3,
          src: require("@/assets/img/toolbox/brightness.png"),
          name: "照度计",
        },
        {
          answer: 5,
          src: require("@/assets/img/toolbox/pressure.png"),
          name: "微压仪",
        },
        {
          answer: 4,
          src: require("@/assets/img/toolbox/wind.png"),
          name: "风速仪",
        },
      ],
      toolSelect:-1,//选择的答案
    };
  },
  props: {
    urls: [],
    branchKey:0,
    visible:false,
  },

  watch:{
    visible(value){
      if(value){
        this.removeBg();
        this.toolSelect=-1;
      }
    },
  },
  methods:{
    removeBg(){
      document.querySelectorAll('.tool').forEach(function (v,k) {
        v.classList.remove('tool-bg')
      });
    },
    selectTool(select_,index,event){
      //选择工具箱工具
      document.querySelectorAll('.tool').forEach(function (v,k) {
        v.classList.remove('tool-bg')
      });

      event.currentTarget.classList.add('tool-bg')
      this.toolSelect=index;//只能定义,当前选择了这个
      this.$emit('customPlay2', '5/5-5-2-tool-select.wav');
    },
    subToolBox(){
      if(this.toolSelect<=-1){
        return;
      }
      // 工具箱确定
      let select_=this.toolboxImg[this.toolSelect];
      this.$emit('subToolAnswer', select_['answer'],this.branchKey,select_['src']);
      this.$emit('customPlay2', 'button.mp3');
    },
  }
};

</script>

<style lang="less" scoped>

.toolboxs {
  .toolbox {
    position: absolute;
    top: 20%;
    right: 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 18rem;
    background-color: #fff;
    border-radius: 0.625rem;
  }
  .title {
    background-color: #409eff;
    color: #fff;
    font-size: 1.125rem;
    padding: 0.6rem 0.4rem;
    border-radius: 0.625rem 0.625rem 0 0;
  }
  .tools {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0.625rem 0.5rem;
    -ms-overflow-style: none;
  }
  .tool {
    width: 31%;
    font-size: 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 0.5rem;
    margin-bottom: 0.625rem;
    border: 1px solid rgba(64, 158, 255, 0.8);
    border-radius: 0.1875rem;
    cursor: pointer;
  }
  .tool:nth-child(3n+3){
    margin-right: 0;
  }
  .tool-bg {
    background: #cae1ffc6;
  }
  img {
    width: 5rem;
    margin: 0.5rem 0;
  }
  .vertical-scroll {
    position: absolute;
    right: 0;
    width: 0.5rem;
    top: 2.5rem;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    visibility: visible;
    background-color: #fff;
    height: 15.625rem;
    overflow: hidden;
  }
  .confirm {
    text-align: center;
    padding: 5px;
  }
}
</style>