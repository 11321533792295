<!--末端配电装置-->
<template>
  <el-dialog :visible="visible" :showClose="false" :modal="false" lock-scroll style="position: absolute">
<!--    柜体-->
    <div class="cabinet" v-if="parameter.cabinet">
      <img :src="zoomOutImg" class="zoomOut" @click="onClose" />
      <div class="xiaoapx">消防箱APX</div>
      <div class="box">
        <div class="deng">
          <div class="item">
            <div class="label">主电指示灯</div>
            <div class="light" :class="parameter.class&&parameter.class.zhudian?parameter.class.zhudian:''"></div>
          </div>
          <div class="item">
            <div class="label">主电合闸灯</div>
            <div class="light" :class="parameter.class&&parameter.class.zdhezha?parameter.class.zdhezha:''"></div>
          </div>
          <div class="item">
            <div class="label">备电指示灯</div>
            <div class="light" :class="parameter.class&&parameter.class.beidian?parameter.class.beidian:''"></div>
          </div>
          <div class="item">
            <div class="label">备电合闸灯</div>
            <div class="light" :class="parameter.class&&parameter.class.bdhezha?parameter.class.bdhezha:''"></div>
          </div>
        </div>
        <img class="bashou" src="../../../assets/img/terminalElectric/bashou.png"/>
        <div class="weixian">
          <img src="../../../assets/img/terminalElectric/weixian.png" />
        </div>
      </div>
    </div>
<!--    把手-->
    <div class="handle" v-if="parameter.handle">
      <img :src="zoomOutImg" class="zoomOut" @click="onClose" />
      <img class="handleImg" :src="parameter.img1" v-if="parameter.switchOn"/>
      <img class="handleImg" :src="parameter.img2" v-else/>
      <img :class="parameter.class&&parameter.class.bopian?parameter.class.bopian:'bopian'" @click="buttonSelect('bopian')" src="../../../assets/img/terminalElectric/bopian.png">
      <div v-if="parameter.switchOn" @click="buttonSelect('son')" class="switch"></div>
      <div v-else @click="buttonSelect('soff')" class="switch" ></div>
      <img @click="buttonSelect('srotate')" :class="parameter.class&&parameter.class.rotate?parameter.class.rotate:'rotate'" src="../../../assets/img/terminalElectric/rotate.png">

      <div class="light zhudian" :class="parameter.class&&parameter.class.zhudian?parameter.class.zhudian:''"></div>
      <div class="light beidian" :class="parameter.class&&parameter.class.beidian?parameter.class.beidian:''"></div>
      <div class="light zdhezha" :class="parameter.class&&parameter.class.zdhezha?parameter.class.zdhezha:''"></div>
      <div class="light bdhezha" :class="parameter.class&&parameter.class.bdhezha?parameter.class.bdhezha:''"></div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    parameter: {},
    close: {
      type: Function,
    },
  },
  data() {
    return {
      zoomOutImg:require('@/assets/img/zoomOut.png'),

      closeAudio:'magnifying-xiao.mp3',//弹窗关闭声音
      keyboardAudio:'keyboard.mp3',//按钮声音
      isShow: false,
    };
  },
  mounted() {
  },
  created() {
    // console.log(this.parameter)
  },

  methods: {
    show() {
      this.isShow = true;
    },
    onClose() {
      this.close && this.close();
      this.$emit('customPlay2', this.closeAudio);
    },
    buttonSelect(value,e) {
      console.log('terminalElectric-buttonSelect',value,this.parameter[value])
      if(this.parameter[value]){
        if(this.parameter[value]['up']&&(!this.parameter[value]['submit']||(this.parameter[value]['submit']&&(this.parameter[value]['submit']['equal']===1&&this.parameter.branchKey===this.parameter[value]['submit']['branch']||this.parameter[value]['submit']['equal']===3)))){
          let arr=this.parameter[value]['up'];
          let new_=this.arrcover(arr,this.parameter)
          this.$set(this.parameter,new_)
          console.log('buttonSelect-up',value)
        }
      }
      let submit=[];let update_=[];
      if(this.parameter[value]&&this.parameter[value]['submit']){
        submit=this.parameter[value]['submit'];
      }
      if(this.parameter[value]&&this.parameter[value]['update']){
        console.log('subUpdate1',this.parameter[value]['update'])
        update_= this.parameter[value]['update'];
      }

      this.$emit('customOther', value,submit,update_);
      // this.$emit('customPlay2', this.keyboardAudio);
    },
    arrcover(arr,new_){
      Object.keys(arr).forEach((k)=>{
        if(typeof arr[k] === 'object'){
          if(!new_[k]){
            new_[k]={};
          }
          this.arrcover(arr[k],new_[k])
        }
        else{
          new_[k]=arr[k];
        }
      })
      return new_;
    },
  },
};
</script>

<style lang="less" scoped>
.el-dialog__wrapper {
  position: absolute;
}
.hiden {
  display: none !important;
}
/deep/.el-dialog {
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: calc(100% - 1.875rem);
  max-width: calc(100% - 1.875rem);
  background: transparent;
  box-shadow: none;
  width: auto;
}
.zoomOut{
  position: absolute;
  right: 1rem;
  top: 1rem;
}
/deep/.el-dialog .el-dialog__body {
  max-height: 100%;
  flex: 1;
  -ms-flex: 1 1 auto;
  overflow-y: hidden;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cabinet{scale: .9;
  position: relative;
  border: 0.5rem solid #fff;
  border-radius: 1.25rem;
  background: url(../../../assets/img/terminalElectric/1.png) repeat;
  background-size: 160px 160px;
  width: 41rem;
  height: 57.75rem;
  display: -webkit-box;
  display: -ms-flexbox;
  //display: flex;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  .zoomOut{
    top: 1.5rem;
    right: 1.5rem;
  }
  .xiaoapx{
    width: 100%;
    border-radius: 0.9rem 0.9rem 0 0;
    text-align: right;
    color: white;
    height: 5rem;
    background-color: #9c010f;
    font-size: 3rem;
    line-height: 5rem;
    padding-right: 3.7rem;
    font-weight: 600;
  }
  .deng{
    display: flex;
    padding: 2.5rem 3.75rem;
    -webkit-box-align: center;
    -ms-flex-align: center;
    justify-content: space-between;
    .item{
      //width: 25%;
      align-items: center;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      font-size: 1.125rem;
      -ms-flex-align: center;
      display: flex;
      .label{
        background-color: #9c010f;
        padding: 0.9375rem 0.625rem;
        font-weight: 800;
        color: #fff;
        border-bottom: 0.5rem solid #0069ed;
      }
      .light{
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 2.5rem;
        border: 1px solid #333;
        margin-top: 1.875rem;
        background-color: #c8cccb;
      }
      .green{
        background-color: #a0ffa5;
      }
      .red{
        background-color: #ffa39c;
      }
      .grey{
        background-color: #c8cccb;
      }
    }
  }
  .bashou{
    position: absolute;
    top: 45%;
    left: 85%;
    width: 3rem;
  }
  .weixian{
    margin: 0 auto;
    margin-top: 20rem;
    text-align: center;
    img{
      width:23rem;
    }
  }
}
.handle{
  position: relative;
  border: 0.5rem solid #fff;
  border-radius: 0.5rem;
  .handleImg{
    width: 67rem;
  }
  .bopian{
    position: absolute;
    top: 24.04rem;
    left: 30rem;
    width: 1.2rem;
    transition: all 0.5s ease-in-out;
  }
  .bopian-shoudong{
    position: absolute;
    top: 24.04rem;
    left: 32.1rem;
    width: 1.2rem;
    transition: all 0.5s ease-in-out;
  }
  .rotate{
    position: absolute;
    top: 27rem;
    left: 29.5rem;
    width: 4.5rem;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    transition: transform 0.5s linear;
  }
  .rotate-beiyong{
    position: absolute;
    top: 27rem;
    left: 29.5rem;
    width: 4.5rem;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    transition: transform 0.5s linear;
  }
  .switch{
    position: absolute;
    top: 4.9rem;
    left: 16.8rem;
    width: 3rem;
    height: 3rem;
  }
  .light{
    position: absolute;
    width: 0.375rem;
    height: 0.375rem;
    border-radius: 0.375rem;
    -webkit-box-shadow: 1px 1px 2px #333, inset 0 0 2px #fff;
    box-shadow: 1px 1px 2px #333, inset 0 0 2px #fff;
    background: white;
  }
  .zhudian{
    top: 22.9rem;
    left: 26.85rem;
  }
  .beidian{
    top: 22.9rem;
    left: 36.2rem;
  }
  .zdhezha{
    top: 33rem;
    left: 26.85rem;
  }
  .bdhezha{
    top: 33rem;
    left: 36.5rem;
  }
  .red{
    background: red;
  }
}


</style>
